/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, OrbitControls } from "@react-three/drei";
import { Canvas } from '@react-three/fiber';


const background = require('../../assets/3dModels/background002.gltf')


function Model(props) {
    const { nodes, materials } = useGLTF(background)

    const noSpin = useRef()
    const cubeCenter = useRef()
    const centerCone = useRef()
    const lowerLeftTriangle = useRef()
    const leftPlus = useRef()
    const upperLeftWireframe = useRef()
    const lowerRightPlus = useRef()
    const lowerRightPyramidWireframe = useRef()
    const upperRightWiggle = useRef()
    const centerPyramidWireframe = useRef()
    useEffect(() => {
        // Start or stop animations based on the `props.start` value
        const intervalId = setInterval(() => {
            if (props.start) {
                lowerLeftTriangle.current.rotation.z += (1 /150)
                lowerLeftTriangle.current.rotation.y += (1 /150)
                leftPlus.current.rotation.z += (1 /150)
                leftPlus.current.rotation.x += (1 /150)
                upperLeftWireframe.current.rotation.z += (1 /150)
                upperLeftWireframe.current.rotation.y += (1 /150)
                lowerRightPlus.current.rotation.z += (1 /150)
                lowerRightPyramidWireframe.current.rotation.y += (1 /150)
                cubeCenter.current.rotation.y += (1 /150)
                centerCone.current.rotation.z += (1 /150)
                upperRightWiggle.current.rotation.y -= (1 /150)
                centerPyramidWireframe.current.rotation.y += (1 /150)
            }
        }, 1000 / 60); // 60 FPS

        return () => {
            // Clean up the interval when the component unmounts or `props.start` changes
            clearInterval(intervalId);
        };
    }, [props.start]);

    return (
        <group  {...props} dispose={null} position={[0, 0, -300]}>
            {/* center Cube */}
            <group ref={props.start ? cubeCenter : noSpin} position={[15.14, -78.31, 0,]}   >
                <mesh castShadow receiveShadow geometry={nodes.Cube001.geometry} material={materials.pinkToonShader} />
                <mesh castShadow receiveShadow geometry={nodes.Cube001_1.geometry} material={materials.blackEdge} />
            </group>
            {/* center cube wireframe */}
            <mesh castShadow receiveShadow geometry={nodes.cubeCenterWireframe.geometry} material={materials.greenToonShader} position={[-1.74, -69.1, 81.44,]} />
            {/* center pyramid */}
            <group position={[-12.83, 71.42, 5.45,]}   >
                <mesh castShadow receiveShadow geometry={nodes.Cube003.geometry} material={materials.greenToonShader} />
                <mesh castShadow receiveShadow geometry={nodes.Cube003_1.geometry} material={materials.blackEdge} />
            </group>
            {/* center pyramid wireframe */}
            <mesh ref={props.start ? centerPyramidWireframe : noSpin} castShadow receiveShadow geometry={nodes.pyramidCenterWireframe.geometry} material={materials.purpleToonShader} position={[13.05, 79.69, 87,]} />
            {/* center cone */}
            <mesh ref={props.start ? centerCone : noSpin} castShadow receiveShadow geometry={nodes.coneCenter.geometry} material={materials.purpleToonShader} position={[17.33, -5.58, 0,]} rotation={[-Math.PI / 2, 0, 0,]} />
            {/* center Wiggle */}
            <group position={[-25.58, 4.08, 0,]} rotation={[0, 0, -0.77,]}   >
                <mesh castShadow receiveShadow geometry={nodes.Cube005.geometry} material={materials.pinkToonShader} />
                <mesh castShadow receiveShadow geometry={nodes.Cube005_1.geometry} material={materials.blackEdge} />
            </group>
            <group position={[-88.5, -99.58, 24.77,]} rotation={[0, 0, 0.52,]}   >
                <mesh castShadow receiveShadow geometry={nodes.Cube006.geometry} material={materials.purpleToonShader} />
                <mesh castShadow receiveShadow geometry={nodes.Cube006_1.geometry} material={materials.blackEdge} />
            </group>
            <mesh castShadow receiveShadow geometry={nodes.pyramidLeftCenterireframe.geometry} material={materials.pinkToonShader} position={[-79.39, -89.69, 62.63,]} rotation={[0, 0, 0.52,]} />
            <group position={[77.69, 98.52, 56.62,]} rotation={[0, 0, -Math.PI / 4,]}   >
                <mesh castShadow receiveShadow geometry={nodes.Cube008.geometry} material={materials.purpleToonShader} />
                <mesh castShadow receiveShadow geometry={nodes.Cube008_1.geometry} material={materials.blackEdge} />
            </group>
            <mesh castShadow receiveShadow geometry={nodes.cuberRightWireframe.geometry} material={materials.greenToonShader} position={[77.69, 105.83, 86.02,]} rotation={[0, 0, -Math.PI / 4,]} />
            <mesh castShadow receiveShadow geometry={nodes.coneLeft.geometry} material={materials.pinkToonShader} position={[-79.11, 40.1, 0,]} rotation={[-Math.PI / 2, 0, 0,]} />
            <group position={[80.64, -63.36, 0,]} rotation={[0, 0, 0.22,]}   >
                <mesh castShadow receiveShadow geometry={nodes.Cube010.geometry} material={materials.purpleToonShader} />
                <mesh castShadow receiveShadow geometry={nodes.Cube010_1.geometry} material={materials.blackEdge} />
            </group>
            <group position={[-78.32, 101.95, 0,]} rotation={[0, 0, -0.51,]}   >
                <mesh castShadow receiveShadow geometry={nodes.Cube011.geometry} material={materials.greenToonShader} />
                <mesh castShadow receiveShadow geometry={nodes.Cube011_1.geometry} material={materials.blackEdge} />
            </group>
            {/* upper Right Wiggle */}
            <group ref={props.start ? upperRightWiggle : noSpin} position={[192.16, 103.49, 0,]} rotation={[0, 0, 1.96,]}   >
                <mesh castShadow receiveShadow geometry={nodes.Cube012.geometry} material={materials.greenToonShader} />
                <mesh castShadow receiveShadow geometry={nodes.Cube012_1.geometry} material={materials.blackEdge} />
            </group>
            <mesh castShadow receiveShadow geometry={nodes.coneRight.geometry} material={materials.pinkToonShader} position={[189.61, 3.81, 0,]} rotation={[-Math.PI / 2, 0, 0,]} />
            {/* Upper Left Wireframe */}
            <mesh ref={props.start ? upperLeftWireframe : noSpin} castShadow receiveShadow geometry={nodes.cuberWireframeLeft.geometry} material={materials.purpleToonShader} position={[-203.78, 93.26, 81.44,]} rotation={[0, 0, 0.38,]} />
            {/* lower right plus */}
            <group ref={props.start ? lowerRightPlus : noSpin} position={[226.4, -108.26, 81.44,]}   >
                <mesh castShadow receiveShadow geometry={nodes.Cube016.geometry} material={materials.purpleToonShader} />
                <mesh castShadow receiveShadow geometry={nodes.Cube016_1.geometry} material={materials.blackEdge} />
            </group>
            {/* lower left Plus */}
            <group ref={props.start ? leftPlus : noSpin} position={[-156.63, 3.28, 0,]} rotation={[0, 0, 0.16,]}   >
                <mesh castShadow receiveShadow geometry={nodes.Cube017.geometry} material={materials.pinkToonShader} />
                <mesh castShadow receiveShadow geometry={nodes.Cube017_1.geometry} material={materials.blackEdge} />
            </group>
            {/* Lower right triangle */}
            <mesh ref={props.start ? lowerRightPyramidWireframe : noSpin} castShadow receiveShadow geometry={nodes.pyramidWireframeRight.geometry} material={materials.pinkToonShader} position={[225.88, -90.51, 81.44,]} />
            {/* lowerLeftTriangle */}
            <group ref={props.start ? lowerLeftTriangle : noSpin} position={[-189.29, -87.81, 5.45,]} rotation={[0, 0, -0.77,]}   >
                <mesh castShadow receiveShadow geometry={nodes.Cube019.geometry} material={materials.greenToonShader} />
                <mesh castShadow receiveShadow geometry={nodes.Cube019_1.geometry} material={materials.blackEdge} />
            </group>
        </group>
    )
}

useGLTF.preload(require('../../assets/3dModels/background002.gltf'))

export default function BackgroundShapes(props) {

    return <div style={{ zIndex: "-1", position: "fixed", height: "100dvh", width: "100dvw", pointerEvents: "none" }}>
        <Canvas orthographic camera={{ zoom: 2.5, position: [0, 0, 0] }} >
            <OrbitControls enablePan={false} enableZoom={false} enableRotate={true} default={true} />
            <ambientLight intensity={0.5} />
            <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
            <pointLight position={[-10, -10, -10]} />
            {/* <pointLight position={[5, 5, 5]} /> */}
            <Model start={props.start} />
        </Canvas>
    </div >
}