/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState } from "react";
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, OrbitControls } from "@react-three/drei";

const envelope = require('../../assets/3dModels/envelope.gltf')

export function Model(props) {
  const { nodes, materials } = useGLTF(envelope);
  const [open, setOpen] = useState(false)

  const flap = useRef();

  // const  flapOpen = () => {
  //   setInterval(() => {
  //     clearInterval(flapOpen())
  //     if (flap.current.rotation.x > -3 && open == true) {
  //       flap.current.rotation.x -= .1
  //     } else {
  //       clearInterval(flapOpen())
  //     }
  //   }, 50)
  // }

  // const flapClose = () => {
  //   const close = setInterval(() => {
  //     // clearInterval(flapOpen())
  //     if (flap.current.rotation.x < -.1 && open == false) {
  //       console.log(flap.current.rotation.x)
  //       flap.current.rotation.x += .1
  //     } else {
  //       console.log("closed interval")
  //       clearInterval(close)

  //     }
  //   }, 50)

  // }

  useFrame((state, delta) => {
    if (open) {
      flap.current.rotation.x = Math.max(flap.current.rotation.x - delta * 2, -3);
    } else {
      flap.current.rotation.x = Math.min(flap.current.rotation.x + delta * 2, -0.1);
    }
  })

  return (
    <group {...props} dispose={null} onPointerEnter={() => {
      setOpen(true)
    }}

      onPointerLeave={() => {
        setOpen(false)
      }}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube.geometry}
        material={materials.enveloper}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube_1.geometry}
        material={materials["Material.001"]}
      />
      <group ref={flap} position={[0, 1, 0.04]} rotation={[-.1, 0, 0]} >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001.geometry}
          material={materials.enveloper}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_1.geometry}
          material={materials["Material.001"]}
        />
      </group>
      <group position={[0, 0.2, 0]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane.geometry}
          material={materials.Material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane_1.geometry}
          material={materials["Material.001"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text.geometry}
        material={materials["Material.002"]}
        position={[-0.68, 0.46, 0.04]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.6}
      />
    </group>
  );
}

useGLTF.preload(envelope);

export default function Envelope(props) {

  return <div style={{ height: "30dvh", pointerEvents: "none" }}>
    <Canvas   >
      <OrbitControls enablePan={false} enableZoom={false} enableRotate={true} default={true} />
      <ambientLight intensity={0.5} />
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
      <pointLight position={[-10, -10, -10]} />
      {/* <pointLight position={[5, 5, 5]} /> */}
      <Model />
    </Canvas>
  </div >
}